<template lang="pug">
v-row
  v-container.my-10(fill-height, fluid, v-if="step == 0")
    v-row(align="center", justify="center")
      v-col(cols="12", sm="8", md="6")
        v-card.elevation-12
          v-toolbar(color="primary", dark, flat)
            v-toolbar-title Create a Power Trip engagement campaign
          v-card-text
            v-form(ref="form", v-model="valid", lazy-validation)
              .text-h6 Reward kiwis who visit you with Lightning Points
              h4.mt-4 Cover image
              v-image-input(
                v-model="headerImage",
                :image-quality="1.0",
                clearable,
                image-format="jpeg",
                :imageWidth="780",
                :imageHeight="250"
                uploadIcon="mdi-camera"
                overlayPadding="0px"
              )
              h4 Business logo
              v-image-input(
                v-model="logoImage",
                :image-quality="1.0",
                clearable,
                image-format="jpeg",
                style="margin-left: 50%; transform: translate(-50%)"
                uploadIcon="mdi-camera"
                overlayPadding="0px"
              )

              v-text-field(
                v-model="campaign.name",
                label="Your business or sponsorship name",
                :rules="[rules.required, rules.minimum]"
              )
              v-textarea(
                v-model="campaign.description",
                label="Extra information (optional)",
                hint="This is what conscious commuters read first when they check out your campaign.",
                :rules="[rules.required, rules.minimum]"
              )
              v-text-field(
                v-model="campaign.referral"
                label="Referral Code (Optional)"
                hint="If you were referred by a business enter their referral code here."
              )
            v-card-actions
              v-btn(text, @click="later") Later
              v-spacer
              v-btn(color="primary", @click="next") Next
  v-container.my-10(fill-height, fluid, v-if="step == 1")
    v-row(align="center", justify="center")
      v-col(cols="12", sm="8", md="4")
        v-card.elevation-12
          v-toolbar(color="primary", dark, flat)
            v-toolbar-title Where do you want people to visit?
          v-card-text
            v-form(ref="form2", v-model="valid2", lazy-validation)
              v-text-field(
                v-model="campaign.location.name",
                label="Give that place a name",
                :rules="[rules.required, rules.minimum]"
              )
              AddressFinder.mb-5(
                :model="campaign.location.address",
                :clear="true",
                label="Address",
                :rules="[rules.required, rules.minimum]"
              )
              v-text-field(
                v-model="campaign.location.geoFenceRange",
                type="number",
                label="Check-in distance",
                suffix="metres",
                :rules="rangeRules",
                hint="How close do people need to be to claim points?"
              )
          v-card-actions
            v-btn(text, @click="back") back
            v-spacer
            v-btn(color="primary", @click="next") Next
  v-container.my-10(fill-height, fluid, v-if="step == 2")
    v-row(align="center", justify="center")
      v-col(cols="12", sm="8", md="4")
        v-card.elevation-12
          v-toolbar(color="primary", dark, flat)
            v-toolbar-title Add engagement!
          v-card-text
            v-expansion-panels(
              flat,
              v-model="panel",
              v-if="showAction",
              :disabled="panel != null"
            )
              ActionCard(
                ref="act",
                :action="campaign.action",
                @remove="deleteAction"
              )
            v-btn.primary.my-5(fab, @click="addAction", v-if="!showAction")
              v-icon mdi-gesture-tap
          v-card-actions
            v-btn(text, @click="back") back
            v-spacer
            v-btn(color="primary", @click="next") Next
  v-container.my-10(fill-height, fluid, v-if="step == 3")
    v-row(align="center", justify="center")
      v-col(cols="12", sm="8", md="4")
        v-card.elevation-12
          v-toolbar(color="primary", dark, flat)
            v-toolbar-title Let's go
          v-card-text
            .text-h6 We don't charge subscriptions or extra fees. We only bill you for points you add to the map.
            .text-center
              a(href="https://powertrip.earth/terms-and-conditions") Terms and Conditions
            v-stripe-card(
              v-model="source",
              ref="stripeCard",
              @focus="onFocus",
              @input="onProcessed",
              api-key="pk_test_piJkpNBbxRaqA3M6oWjmWAQb0057E6vl6y",
              :hidePostalCode="true",
              create="source"
            )

          v-card-actions
            v-btn(text, @click="back") back
            v-spacer
            v-btn(color="primary", @click="next") Next
  v-snackbar(v-model="snackbar", :timeout="4000") {{ messageText }}
</template>

<script>
import VImageInput from "vuetify-image-input/a-la-carte";
import AddressFinder from "../components/AddressFinder";
import ActionCard from "../components/ActionCard";
import PowerTrip from "../services/api/PowerTrip";
import axios from "axios";
import Vue from "vue";
export default {
  components: { VImageInput, AddressFinder, ActionCard },
  data() {
    return {
      messageText: "",
      snackbar: false,
      valid: true,
      valid2: true,
      campaign: { location: { address: { Address: "" } }, action: {} },
      headerImage: null,
      logoImage: null,
      showAction: false,
      panel: null,
      source: null,
      stripeCardInitialized: false,
      user: this.$user,
      step: 0,
      rangeRules: [
        (v) => !!v || "You need to specify a range for this location",
        (v) => (v && v <= 200) || "Range should be no more than 200m",
        (v) => (v && v >= 5) || "Range should be at least 5m",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        minimum: (value) =>
          (!!value && value.length >= 8) || "Minimum 8 Characters",
        counter: (value) =>
          (!!value && value.length <= 20) || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  async mounted() {
    // let c = await PowerTrip.getCampaign();
    //if (!c) {
    //   this.$router.push("/login");
    //} s
    // await this.updateUser();
  },

  methods: {
    async updateUser() {
      Vue.prototype.$user = await PowerTrip.login();
      localStorage.setItem("token", this.$user.token);
      axios.defaults.headers.common["X-Auth-Powertrip"] = this.$user.token;
      this.user = this.$user;
    },
    onFocus: function () {
      if (!this.stripeCardInitialized) {
        this.stripeCardInitialized = true;
        this.$watch(
          () => this.$refs.stripeCard.okToSubmit,
          (value) => {
            console.log(value);
            this.$refs.stripeCard.processCard();
          }
        );
      }
    },

    onProcessed: function () {
      if (!this.processing) {
        this.processing = true;
        this.user.cardLastDigits = this.source.card.last4;
        this.user.cardExpiry =
        this.source.card.exp_year + "-" + this.source.card.exp_month + "-01";
        this.user.stripeSecret = this.source.client_secret;
        this.user.stripePaymentMethod = this.source.type;
        this.user.stripeID = this.source.id;
        this.paymentDialog = false;
        this.clickToClose = true;
        PowerTrip.saveUserData(this.user).then(() => {
          this.$emit("userUpdated");
          this.processing = false;
        });
      }
    },
    later() {
      this.$router.push("/");
    },
    back() {
      this.step--;
    },
    next() {
      let valid = false;
      switch (this.step) {
        case 0:
          valid = this.$refs.form.validate();
          break;
        case 1:
          valid = this.$refs.form2.validate();
          break;
        case 2:
          valid = this.$refs.act.$refs.form.validate();
          break;
        case 3:
          valid = this.user.stripeID == !!this.user.stripeID;
          break;
      }
      if (valid) {
        if (this.step < 3) {
          this.step++;
        } else {
          this.messageText = "Saving";
          this.snackbar = true;
          this.save();
        }
      }
    },
    async save() {
      if (this.headerImage) {
        this.campaign.header = await this.processUpload(
          this.headerImage.imageURL
        );
        let parts = this.campaign.header.split(",");
        if (parts.length == 2) {
          this.campaign.header = parts[1];
          this.campaign.headerExt = parts[0].match(
            /data:image\/([a-zA-Z]+);base64/
          )[1];
        }
        this.headerImage = null;
      }
      if (this.logoImage) {
        this.campaign.logo = await this.processUpload(this.logoImage.imageURL);
        let parts = this.campaign.logo.split(",");
        if (parts.length == 2) {
          this.campaign.logo = parts[1];
          this.campaign.logoExt = parts[0].match(
            /data:image\/([a-zA-Z]+);base64/
          )[1];
        }
        this.logoImage = null;
      }
      this.campaign.locations = [this.campaign.location];
      this.campaign.actions = [this.campaign.actions];
      await PowerTrip.saveCampaign(this.campaign);
      this.$router.push("/");
    },
    deleteAction() {
      this.campaign.action = {};
      this.campaign.action.enabled = true;
      this.campaign.action.locationRequired = true;
      this.showAction = false;
    },
    addAction() {
      this.showAction = true;
      this.panel = 0;
    },

    async processUpload(url) {
      let blob = await fetch(url).then((r) => r.blob());
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
  },
};
</script>